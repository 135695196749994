import React from 'react';
import SEO from 'react-seo-component';
import Layout from '~layouts/mainLayout';
import WelcomeSection from '~components/welcome';
import ProjectsSection from '~components/projects';
import MusicSection from '~components/music';
import BlogSection from '~components/blog';
import { useSiteMetadata } from '~hooks/useSiteMetadata';
import TestimonialsSection from '~components/testimonials';
import IntroCase from '~components/introCase';
import CaseBody from "~components/caseBody";
import metavader from "../assets/images/metavaders.png"
import { FaArrowRight } from 'react-icons/fa';
import clientWork5 from "../assets/images/clientWork5.png";
import Fade from "react-reveal/Fade";
export default function Home() {
  const {
    title,
    titleTemplate,
    description,
    image,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
  } = useSiteMetadata();
  return (
    <>
      <SEO
        title={title}
        titleTemplate={titleTemplate}
        titleSeparator="|"
        description={description || 'Welcome to my’'}
        image={`${siteUrl}${image}`}
        pathname={siteUrl}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
      />
      <Layout>
        <IntroCase title="Boook" subtitle="Building bots with Puppeteer which will automate login, creating new products, evaluate data and interacting with a custom backend for a marketplace app.
" />
        <section className="mb-24">
     
        <div class="flex flex-wrap text-center justify-center ">
        <Fade bottom delays={700}>
        <h2 className="sm:text-lg sm:leading-snug font-semibold tracking-wide uppercase text-amber mb-3">
      Challenge
    </h2>
    <p className="text-secondary prose-lg mb-6">
   A startup wanted to automate their data entry each time they had a new client who had info available on their website or somewhere public. At multiple occasions, they were faced with entering more than 100 products which included different sub-categories. The tasks could take 3-4 hours to manually setup the profile of their new clients. The goal was to automate the majority of those manual tasks. </p>
   </Fade>
      </div>
    
      <div class="container p-4 mb-10 lg:20 lg:px-20 mx-auto text-center ">
		<div class="relative w-full my-4 mr-auto rounded-2xl ">
			<img alt="Card" src={clientWork5} class="max-w-full rounded-lg "/>
        </div>
	
                <div class="col-span-12 lg:col-span-8 text-center">
                <div class="inline-block rounded-full text-white 
                            bg-blue-400 hover:bg-blue-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                          Puppeteer
                        </div>
                        
                        <div class="inline-block rounded-full text-white 
                            bg-red-400 hover:bg-red-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                           Node.js
                        </div>
                        <div class="inline-block rounded-full text-white 
                            bg-red-400 hover:bg-red-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                           MongoDB
                        </div>
                        <div class="inline-block rounded-full text-white 
                            bg-yellow-400 hover:bg-yellow-500 duration-300 
                            text-xs font-bold 
                            mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 
                            opacity-90 hover:opacity-100">
                          Typescript
                        </div>
                      
                          
                    </div>
  </div>
 

      <div class="flex flex-wrap text-center justify-center mb-20">
      <Fade  delays={700}>
        <h2 className="sm:text-lg sm:leading-snug font-semibold tracking-wide uppercase text-green-500 mb-3">
      Solution
    </h2>
    <p className="text-secondary prose-lg mb-6">
  I needed to combine web scrapping, completing input forms, evaluating available data on the custom database to finally uploading the product live. I opted for <a href="https://pptr.dev/" target="_blank"><strong>Puppeeteer</strong></a> which is a Node library that provides a high-level API to perform all the manual things one perform in a browser. Consequently, I decided to have a simple UI built with Javascript-Tailwind and for database I went with MongoDB. I ran the logic and performed the whole task manually in order to understand each steps that the bots would have to performed then the rest was translating those steps into algorithm.
<br />
<br />
</p>
</Fade>
    
      </div>
    
    
    </section>
      </Layout>
    </>
  );
}
